import React, { useEffect, useState } from 'react';

import { ApiUrl, GetQuoteId } from '../../utils/utils';
import axios from 'axios';
import LoaderImg from "../../assets/images/loader.gif";

import {
  CardElement,
  useStripe,
  useElements,
  PaymentElement
} from "@stripe/react-stripe-js";


function CheckoutForm() {
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [clientSecret, setclientSecret] = useState(null);
  const [clientSecretVal, setSlientSecretVal] = useState(false);
  const [qouteData, setQouteData] = useState('');
  const [paymentstage, setPaymentStage] = useState('S');
  const[isLoader, setisLoader] = useState(false);
  const[ispaymentSuccess, SetisPayementSuccess] = useState(false);
  const[ispaymentErr, SetisPayementErr] = useState(false);
  const[paymentamount, setPaymentAmout] = useState(false);
  const[isProcessing, setisProcessing] = useState(false);
  const[message, setMessage] = useState(false);


  const stripe    = useStripe();
  const elements  = useElements();
  const QuoteId   = GetQuoteId();

  const GetQuote = () => {
         
        const data = {};
        data.QuoteId = QuoteId;
        const option = {
            method: 'POST', headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
            },
            data: data,
            url: `${ApiUrl()}/v1/Quote/GetQuoteDetail`
        };
        axios(option)
            .then((e) => {
               
                setQouteData(e?.data?.data);
                const dt = e?.data?.data?.quotePaymentInfo;

                if(dt?.isDepositAmtPayed == true && dt?.isStageAmtPayed == false){
                    setPaymentStage('S');
                    setPaymentAmout(dt.stagePaymentAmt.toFixed(2));
                }else if(dt?.isStageAmtPayed == true){
                    setPaymentStage('F');
                    setPaymentAmout(dt.balancePaymentAmt.toFixed(2));
                }else if(dt?.isFixedAmtPayed == true){
                     setPaymentStage('F');
                     setPaymentAmout(dt.balancePaymentAmt.toFixed(2));
                }else{
                    setPaymentStage('D');
                    setPaymentAmout(dt.depositPaymentAmt.toFixed(2));
                }
            })
            .catch((err) => {
            })
    }

    const paymentChecout = async(clientSecret) => {
    setPaymentLoading(true);
    const paymentResult = await stripe.confirmCardPayment(clientSecret, {
              payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                  name: qouteData?.customerName,
                  email: qouteData?.customerEmail,
                },
              },
            });
            setPaymentLoading(false);
            if (paymentResult.error) {
              alert(paymentResult.error.message);
            } else {
              if (paymentResult.paymentIntent.status === "succeeded") {
                paymentSeuccess(paymentResult?.paymentIntent?.id);
              }
            }
  }

    //https://ysapi.showmysite.net/api/v1/stripe/PaymentSuccess

    //Deposit-D
    //Stage Payment-S
    //Final-F

    

    useEffect(() => {
    GetQuote();
       
  }, []);


  const payMoney = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
     const data = {};
    data.QuoteId  = QuoteId;
    data.Amount   = paymentamount;
    data.Currency = 'GBP';
    data.Email    = qouteData?.customerEmail;
    data.Name     = qouteData?.customerName;

    const option = {
        method: 'POST', headers: {
            "access-control-allow-origin": "*",
            "content-type": "application/json",
            "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
        },
        data: data,
        url: `${ApiUrl()}/v1/stripe/createPaymentIntent`
    };
    await axios(option)
        .then((e) => {
           
             paymentChecout(e?.data);
        })
        .catch((err) => {
        
            return 'err';
        })


    
  };

  const closePay = () =>{
    window.location.reload();
  }

  const handelSubmit = async (e) => {
    e.preventDefault();
    if(!stripe ||!elements){
      return;
    }
    setisProcessing(true);

    const {error, paymentIntent} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url:`https://yesglazing.showmysite.net/payments`
      },
      redirect: "if_required"
    });

    if(error){
      setMessage(error.message);
    } else if(paymentIntent && paymentIntent.status === 'succeeded'){
      setMessage("Payment Status: Completed");
      paymentSeuccess(paymentIntent?.id);
    } else {
      setMessage("Unexpected Status");
    }
     setisProcessing(false);
    }

    const paymentSeuccess = async (TransactionId) => {
        const data = {};
        data.QuoteId = qouteData?.id;
        data.PaymentStage = paymentstage;
        data.PaymentAmt = paymentamount;
        data.TransactionId = TransactionId;
        
        const option = {
            method: 'POST', headers: {
                "access-control-allow-origin": "*",
                "content-type": "application/json",
                "api_key": "B616VV5-7ED4Q7B-MH2562J-ED281YB",
            },
            data: data,
            url: `${ApiUrl()}/v1/stripe/PaymentSuccess`
        };
        await axios(option)
          .then((e) => {
              if(e?.data == 1){
                SetisPayementSuccess(true);
              }else{
                SetisPayementSuccess(true);
                SetisPayementErr(true);
              }
          })
          .catch((err) => {
              return 'err';
          })
    }


  

  return (
    <div> {!ispaymentSuccess && <div
      style={{
        padding: "3rem",
      }}
    >
    <h3 className="amount_section"><span>Amount :</span> £{paymentamount}</h3>
      <form id="payment-form" onSubmit={handelSubmit}>
      <PaymentElement />
       <button
              className="pay-button"
              disabled={isProcessing}
            >
              {isProcessing ? "Loading..." : "Pay"}
            </button>
      </form>
      <span className="errpaymentmsg">{message}</span>
    </div>}
    {ispaymentSuccess && 

      <div className="">
      {!ispaymentErr && <div className="succpaymentmsg"><h3>{message}</h3>
      
      </div> }
      {ispaymentErr && <h3 className="succpaymentmsg">{message}</h3> }
      </div>
    }
    
    </div>
  );
}
export default CheckoutForm;