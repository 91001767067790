export const ApiUrl = () => {
 return 'https://stagingapi.yesglazing.co.uk/api'
    //  return 'https://apiadmin.yesglazing.co.uk/api'
    //return 'https://localhost:7286/api'
}

export const AdminApiUrl = () => {
    return 'https://stagingplatform.yesglazing.co.uk'
    //return 'https://platform.yesglazing.co.uk' 
   // return "https://localhost:7130"
}

export const SaveUserId = (UserId) => {
    localStorage.setItem('userId', UserId)
}

export const SaveQuoteId = (QuoteId) => {
    localStorage.setItem('QuoteId', QuoteId)
}

export const GetUserId = () => {
    return localStorage.getItem('userId') || null
}

export const GetQuoteId = () => {
    return localStorage.getItem('QuoteId') || null
}

export const StripePublicKey = () => {
    //const keyy = "pk_live_51N91I8JUkUEWNKiUyoFR3QavXBpIbLLiaRKtFEU4bxG4IvduNfIzlS0HUizbuhjFyiEdxKXg2YrjUm95Ukbbenjs002NtkX5Kr";
    const keyy = "pk_test_51N91I8JUkUEWNKiUffRU213gw58TLmpG8Byu0gHZfVIoY21ftiJO7leKUr8y3fvwxAOLwQKLTi03IqCgkDAi2Ujl00bOLHWz9n";
    return keyy;
}

export const StripesandboxPublicKey = () => {
    const keyy = "pk_test_51N91I8JUkUEWNKiUffRU213gw58TLmpG8Byu0gHZfVIoY21ftiJO7leKUr8y3fvwxAOLwQKLTi03IqCgkDAi2Ujl00bOLHWz9n";
    return keyy;
}